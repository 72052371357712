@import "./snippets/variable-font-size.scss";

h1,
h2,
h3,
h4,
h5 {
  &.pi-block__heading {
    margin: 0;
    text-align: left;
  }
}
