.pi-text {
  font-size: 1em;
  display: inline-block;

  &--small {
    font-size: 0.8em;
    display: inline-block;
  }

  &--large {
    font-size: 1.2em;
    display: inline-block;
  }
}
